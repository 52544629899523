.overlay {
  background: linear-gradient(
      180deg,
      rgba(9, 4, 1, 1) 0%,
      rgba(12, 12, 12, 0) 30%
    ),
    linear-gradient(0deg, rgba(9, 4, 1, 1) 0%, rgba(12, 12, 12, 0) 60%),
    radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(9, 4, 1, 1) 70%);
}

.mask {
  mask-image: url("../../public/assets/mask.svg");
  mask-repeat: no-repeat;
  mask-size: 200px;
  background-size: 60px 60px;
  background-clip: padding-box;
  background-image: linear-gradient(
      to right,
      rgba(245, 248, 248, 0.5) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(245, 248, 248, 0.5) 1px, transparent 1px);
}

.mask::before {
  background-size: 60px 60px;
  background-clip: padding-box;
  background-image: linear-gradient(
      to right,
      rgba(245, 248, 248, 0.5) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(245, 248, 248, 0.5) 1px, transparent 1px);
}