@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
    overflow-x: hidden;
  }
}

@layer utilities {
  .nav {
    @apply relative;
  }

  .nav::after {
    @apply content-[''] absolute bg-themed-orange h-[3px] w-[0%] left-0 -bottom-[5px] rounded-xl duration-300;
  }

  .nav:hover::after {
    @apply w-[100%];
  }

  .mask::before {
    @apply content-[''] absolute left-0 top-0 w-full h-full opacity-[0.9] -z-[1];
  }

  .modal,
  .modal-overlay {
    @apply w-screen h-screen fixed inset-0;
  }

  .modal-overlay {
    background: rgba(0,0,0,0.5);
  }
}

body {
  background-color: #0c0c0c;
}

.grid-lines {
  background-color: #180e0a;
  background-size: 60px 60px;
  background-clip: padding-box;
  background-image: linear-gradient(
          to right,
          rgba(245, 248, 248, 0.2) 1px,
          transparent 1px
  ),
  linear-gradient(to bottom, rgba(245, 248, 248, 0.2) 1px, transparent 1px);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #0c0c0c;
}

::-webkit-scrollbar-thumb {
  background: #ef8b60;
}

.loader {
  width: 24px;
  height: 24px;
  border: 5px solid #f5f8f8;
  border-bottom-color: #ef8b60;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.admin-loader {
  width: 16px;
  height: 16px;
  border: 5px solid #f5f8f8;
  border-bottom-color: #0f1011;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}